import React from 'react';
import {
    Modules,
    P410StaticNotFoundPage as P410StaticNotFoundPageProps,
} from '~/lib/data-contract';
import { DynamicBlockList } from '~/templates/blocks';

export const P410StaticNotFoundPage = (props: P410StaticNotFoundPageProps) => {
    return <DynamicBlockList elements={props.pageElements as Modules[]} />;
};
