const vagFontsPath = '/assets/fonts/vag';

export const fontsFace = `
/* VAG Rounded LT CYR W10 Thin */
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/8948c8ff-66e9-4222-b239-ce26ef1031af.woff2") format("woff2"),url("${vagFontsPath}/afe3cffa-3266-457d-bb7c-061f4e12ea34.woff") format("woff");
    font-display: swap;
    font-weight: 300;
    font-style: normal;
  }
  /* VAG Rounded LT CYR W10 Light */
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/f15637af-13c4-424e-b526-ccac1f27584c.woff2") format("woff2"),url("${vagFontsPath}/0247f0f4-14f3-45e4-bc7a-e6a257d44ca5.woff") format("woff");
    font-display: swap;
    font-weight: 400;
    font-style: normal;
  }
  /* VAG Rounded LT CYR W10 Bold */
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/7d366350-0993-4477-b086-e07b481f56ee.woff2") format("woff2"),url("${vagFontsPath}/135ade2a-4386-462a-a0c4-7533dbfa5372.woff") format("woff");
    font-display: swap;
    font-weight: 700;
    font-style: normal;
  }
  /* VAG Rounded LT CYR W10 Black */
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/4190dcb2-c81f-4cf9-92cb-ee7ac3787370.woff2") format("woff2"),url("${vagFontsPath}/fbf1e2ee-1006-43cc-aa9d-1fe152df1d2f.woff") format("woff");
    font-display: swap;
    font-weight: 900;
    font-style: normal;
  }
  /* VAG Rounded LT W01 Thin */
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/753751e3-a547-4d30-b947-3b273c85eed3.woff2") format("woff2"),url("${vagFontsPath}/c95f2739-000f-435b-9f69-b15e08684fbf.woff") format("woff");
    font-display: swap;
    font-weight: 300;
    font-style: normal;
  }
  /* VAG Rounded LT W04 Thin */
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/ef89dd32-852b-44c7-a34a-9bac2b550347.woff2") format("woff2"),url("${vagFontsPath}/36f2e551-9bdf-47f6-afc1-308b60c0d52f.woff") format("woff");
    font-display: swap;
    font-weight: 300;
    font-style: normal;
  }
  /* VAG Rounded LT W01 Light */
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/882d2ff7-f20c-4a57-9eef-762dc3771395.woff2") format("woff2"),url("${vagFontsPath}/8db590ec-51e3-4b26-bd16-e6e2a54b86bd.woff") format("woff");
    font-display: swap;
    font-weight: 400;
    font-style: normal;
  }
  /* VAG Rounded LT W04 Light */
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/8c91a8dc-51eb-44d5-a323-e5cd504d7500.woff2") format("woff2"),url("${vagFontsPath}/7600434a-8435-4938-aeef-9285dd56bdfd.woff") format("woff");
    font-display: swap;
    font-weight: 400;
    font-style: normal;
  }
  /* VAG Rounded LT W01 Bold */
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/1932cf6d-7af4-4bca-8c5d-21dbdb848b41.woff2") format("woff2"),url("${vagFontsPath}/a0ae295c-02a9-4bcd-ab23-4a32452e53c5.woff") format("woff");
    font-display: swap;
    font-weight: 700;
    font-style: normal;
  }
  /* VAG Rounded LT W04 Bold */
  /*
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/01df50be-e1aa-452e-84e2-b0d509606487.woff2") format("woff2"),url("${vagFontsPath}/7b5f33af-2082-4b59-aee5-1eb5023eaec1.woff") format("woff");
    font-display: swap;
    font-weight: 700;
    font-style: normal;
  }
  */
  /* VAG Rounded LT W01 Black */
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/74623a02-2313-4f8b-9478-901e6cbd694b.woff2") format("woff2"),url("${vagFontsPath}/a420c994-dc3d-43df-b2e2-5cc5c4abcdee.woff") format("woff");
    font-display: swap;
    font-weight: 900;
    font-style: normal;
  }
  /* VAG Rounded LT W04 Black */
  @font-face{
    font-family:"VAG Rounded Std";
    src:url("${vagFontsPath}/1d6c8c8f-e043-4884-9b1a-6862cbb2de1c.woff2") format("woff2"),url("${vagFontsPath}/a236b79a-8fc2-4558-865b-c87a3be7f76b.woff") format("woff");
    font-display: swap;
    font-weight: 900;
    font-style: normal;
  }
  
`;
