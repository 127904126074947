import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { minutes } from '../../time';

/**
 * Find default query options as
 * `QueryObserverOptions` at https://github.com/tannerlinsley/react-query/blob/master/src/core/types.ts
 */
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // Disabled since we don't rely on realtime data
            refetchOnWindowFocus: false,
            // Time before revalidating data.
            staleTime: minutes(30),
            // By default we don't want to retry
            retry: false,
            // IMPORTANT:
            // If retryOnMount is not set to true, re- renders from errors can cause a feedback loop causing endless fetching.
            retryOnMount: false,
            // In case we are using retry, make sure there's a moderate delay between requests.
            retryDelay: 1500,
        },
    },
});

export const RequestProvider = ({ children }: { children: ReactNode }) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    );
};
