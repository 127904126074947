import React, { memo, FC, createContext, ReactNode, useState, useEffect } from 'react';
import isBot from 'isbot';

interface BotDetectorProps {
    children?: ReactNode;
}

export const BOT_DETECTOR_CONTEXT = createContext(false);

export const BotDetectorWrapper: FC<BotDetectorProps> = memo(({ children }) => {
    const [visitorIsABot, setVisitorIsABot] = useState(false);

    useEffect(() => {
        // Wrapping this in a try/catch precludes us from having SSR-related problems down the line.
        try {
            setVisitorIsABot(isBot(navigator.userAgent));
        } catch {
            // We don't want to do anything here, it's... well, mostly for show.
        }
    }, []);

    return (
        <BOT_DETECTOR_CONTEXT.Provider value={visitorIsABot}>
            {children}
        </BOT_DETECTOR_CONTEXT.Provider>
    );
});

BotDetectorWrapper.displayName = 'BotDetectorWrapper';
