import styled from '@emotion/styled';

export const StyledErrorContent = styled.div(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.colors.grey10,
    width: '100%',
    padding: `10vh ${theme.spaces[4]}`,
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spaces[4],
    borderRadius: '45px 15px',
}));
