import {
    AccountInfo,
    Configuration,
    EndSessionRequest,
    NavigationClient,
    RedirectRequest,
    SilentRequest,
} from '@azure/msal-browser';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { B2cAdConfiguration } from '..';

export const errorMessages = Object.freeze({
    missingClientId: 'Missing client id, cannot get configuration',
});

// This "loginRedirectPath" should be match to azure b2c allowed redirects path and not relevant to contentful.
export const getAuthConfig: () => B2cAdConfiguration = () => {
    const {
        MYKOMPAN_AUTH_CLIENT_ID,
        MYKOMPAN_AUTH_CLIENT_NAME,
        MYKOMPAN_USER_RESET_PASSWORD_FLOW,
        MYKOMPAN_USER_SIGNIN_KOMPAN_AAD_FLOW,
        MYKOMPAN_USER_SIGNUP_SIGNIN_FLOW,
    } = publicRuntimeConfig();
    return {
        authClientId: MYKOMPAN_AUTH_CLIENT_ID,
        authorityProvider: `https://${MYKOMPAN_AUTH_CLIENT_NAME}.b2clogin.com/${MYKOMPAN_AUTH_CLIENT_NAME}.onmicrosoft.com`,
        knownAuthorities: [`https://${MYKOMPAN_AUTH_CLIENT_NAME}.b2clogin.com`],
        userSignUpSignInFlow: MYKOMPAN_USER_SIGNUP_SIGNIN_FLOW,
        userResetPasswordFlow: MYKOMPAN_USER_RESET_PASSWORD_FLOW,
        kompanAADSignInFlow: MYKOMPAN_USER_SIGNIN_KOMPAN_AAD_FLOW,
    };
};

export const getMsalConfig = (isIE: boolean, navigationClient: NavigationClient): Configuration => {
    const authConfig = getAuthConfig();
    if (!authConfig.authClientId) {
        throw Error(errorMessages.missingClientId);
    }

    return {
        auth: {
            clientId: authConfig.authClientId,
            knownAuthorities: authConfig.knownAuthorities,
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri: authConfig.postLogoutRedirectUri,
        },
        cache: {
            cacheLocation: 'sessionStorage',
            storeAuthStateInCookie: isIE,
        },
        system: {
            navigationClient,
        },
    };
};

export const getSignUpSignInRequest = ({
    userEmail,
    loginRedirectPageUrl,
    state,
    prompt = 'login',
}: {
    userEmail: string;
    loginRedirectPageUrl: string;
    state?: string;
    prompt?: RedirectRequest['prompt'];
}): RedirectRequest => {
    const authConfig = getAuthConfig();
    return {
        authority: `${authConfig.authorityProvider}/${authConfig.userSignUpSignInFlow}`,
        loginHint: userEmail,
        redirectUri: `${loginRedirectPageUrl}`,
        prompt,
        scopes: [],
        state,
    };
};

export const getSignInKompanAADRequest = (
    userEmail: string,
    loginRedirectPageUrl: string,
    state?: string
): RedirectRequest => {
    const authConfig = getAuthConfig();
    return {
        authority: `${authConfig.authorityProvider}/${authConfig.kompanAADSignInFlow}`,
        loginHint: userEmail,
        redirectUri: `${loginRedirectPageUrl}`,
        prompt: 'login',
        scopes: [],
        state: state,
    };
};

export const getSignUpSignInSilentRequest = (
    loginRedirectPageUrl: string,
    account?: AccountInfo | null
): SilentRequest => {
    const authConfig = getAuthConfig();
    return {
        authority: `${authConfig.authorityProvider}/${authConfig.userSignUpSignInFlow}`,
        redirectUri: `${loginRedirectPageUrl}`,
        scopes: [],
        account: account ?? undefined,
    };
};

export const getLogoutRequest = (
    loginPageUrl: string,
    redirectToLogin?: boolean
): EndSessionRequest => {
    const authConfig = getAuthConfig();
    return {
        authority: `${authConfig.authorityProvider}/${authConfig.userSignUpSignInFlow}`,
        postLogoutRedirectUri: redirectToLogin ? `${loginPageUrl}` : '',
    };
};

export const getSilentLogoutRequest = (redirectToPath?: string): EndSessionRequest => {
    const authConfig = getAuthConfig();
    return {
        authority: `${authConfig.authorityProvider}/${authConfig.userSignUpSignInFlow}`,
        postLogoutRedirectUri: redirectToPath ?? '',
    };
};

export const getResetPasswordRequest = ({
    loginRedirectPageUrl,
    state,
    loginHint,
}: {
    loginRedirectPageUrl: string;
    state?: string;
    loginHint?: string;
}): RedirectRequest => {
    const authConfig = getAuthConfig();
    return {
        loginHint: loginHint,
        authority: `${authConfig.authorityProvider}/${authConfig.userResetPasswordFlow}`,
        redirectUri: `${loginRedirectPageUrl}`,
        scopes: [],
        state: state,
    };
};
