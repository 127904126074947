import React, { useState, useEffect, useMemo } from 'react';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import { GlobalStyles, ThemeProvider } from '$theme';
import { RequestProvider } from '$shared/utils';
import { MarketProvider } from '~/shared/utils/market';
import { getLocaleDirection } from '~/shared/utils/translation';
import { DynamicPageProps } from '~/templates/pages';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication, EventMessage } from '@azure/msal-browser';
import {
    getMsalConfig,
    CustomNavigationClient,
    getSignUpSignInSilentRequest,
    getLogoutRequest,
} from '$features/authentication';
import { StorageKey, useStorage } from '$shared/hooks';
import { Toaster } from 'react-hot-toast';
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { GoogleTagManager, NavigatingSpinner } from '~/shared/components';
import { Hydrate } from 'react-query';
import { P410StaticNotFoundPage } from '~/templates/pages/components/P410StaticNotFoundPage';
import { applicationInsightsTelemetryFilter } from '~/shared/utils/applicationInsights/applicationInsightsTelemetryFilter';
import { BotDetectorWrapper } from '$shared/utils/bot-detector';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { myKompanLoginRedirectPageUrlFallback } from '~/lib/constants';
import { MyKompanContextProvider } from '~/shared/utils/myKompanContext/MyKompanContextProvider';

type CustomAppProps<P = unknown> = {
    pageProps: P | undefined;
} & Omit<AppProps<P>, 'pageProps'>;

export const App = ({ Component, pageProps }: CustomAppProps<DynamicPageProps>): JSX.Element => {
    const router = useRouter();
    const [isIE, setIsIE] = useState(false);
    const { isStorageSupported } = useStorage();
    const reactPlugin = useMemo(() => new ReactPlugin(), []);
    const [verifyingToken, setVerifyingToken] = useState(false);

    const { settings } = pageProps ?? {};
    const { isMyKompan, aiInstrumentationKey } = settings ?? {};

    useEffect(() => {
        if (isStorageSupported() && /MSIE|Trident/.test(window.navigator.userAgent)) {
            setIsIE(true);
        }
    }, []);

    const msalInstance = new PublicClientApplication(
        useMemo(() => getMsalConfig(isIE, new CustomNavigationClient(router)), [isIE, router]),
    );

    useEffect(() => {
        // Register applicationInsights
        if (process.env.NODE_ENV === 'production' && aiInstrumentationKey) {
            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: aiInstrumentationKey,
                    enableAutoRouteTracking: true,
                    extensions: [reactPlugin],
                },
            });
            appInsights.addTelemetryInitializer(applicationInsightsTelemetryFilter);
            appInsights.loadAppInsights();
        }

        if (isMyKompan && isStorageSupported()) {
            let activeAccount = msalInstance.getActiveAccount();
            const firstAccount = msalInstance.getAllAccounts()?.[0];
            const savedToken = getCookie(StorageKey.authToken);

            if (!activeAccount && firstAccount) {
                msalInstance.setActiveAccount(firstAccount);
                activeAccount = msalInstance.getActiveAccount();
            }

            if (!(activeAccount || firstAccount) && savedToken) {
                deleteCookie(StorageKey.authToken);
            }

            // Reacquire token if expired or missing
            const hasExpiredToken =
                activeAccount?.idTokenClaims?.exp &&
                activeAccount?.idTokenClaims?.exp * 1000 < Date.now();
            if (activeAccount && (hasExpiredToken || !savedToken)) {
                // Set verifying toggle to prevent showing 401
                setVerifyingToken(true);
                try {
                    msalInstance
                        .acquireTokenSilent(
                            getSignUpSignInSilentRequest(myKompanLoginRedirectPageUrlFallback),
                        )
                        .then((result) => {
                            setCookie(StorageKey.authToken, result.idToken);
                            router.reload();
                        });
                } catch (error) {
                    console.error('Auth acquireTokenSilent Error ', error);
                    deleteCookie(StorageKey.authToken);
                    msalInstance.logoutRedirect(
                        getLogoutRequest(`${frame?.staticLinks?.myKompanLoginPage?.url}`, true),
                    );
                }
            }

            msalInstance.addEventCallback((event: EventMessage) => {
                console.log('Auth event type: ', event.eventType);
            });
        }
    }, []);

    useEffect(() => {
        // to update the lang and dir on HTML
        if (pageProps?.page?.culture) {
            document.documentElement.lang = pageProps?.page?.culture || 'en';
            document.documentElement.dir = getLocaleDirection(pageProps?.page?.culture || 'en');
        }
    }, [pageProps?.page?.culture]);

    if (!pageProps?.page) {
        return <></>;
    }

    if (pageProps?.page.type == 'p410StaticNotFoundPage') {
        return <P410StaticNotFoundPage {...pageProps?.page} />;
    }

    const {
        frame,
        page: { culture, market },
    } = pageProps;

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <MsalProvider instance={msalInstance}>
                <RequestProvider>
                    <Hydrate state={pageProps.dehydratedState}>
                        <BotDetectorWrapper>
                            <MyKompanContextProvider isMyKompan={isMyKompan ?? false}>
                                <ThemeProvider
                                    direction={getLocaleDirection(culture)}
                                    language={culture}
                                >
                                    <GlobalStyles language={culture} />
                                    <MarketProvider value={{ culture, market }}>
                                        {frame?.settings?.googleTagManagerId && (
                                            <GoogleTagManager
                                                googleTagManagerId={
                                                    frame.settings.googleTagManagerId
                                                }
                                            />
                                        )}
                                        <NavigatingSpinner />
                                        <Toaster position="top-right" reverseOrder={false} />
                                        {(!isMyKompan || !verifyingToken) && (
                                            <Component {...pageProps} />
                                        )}
                                    </MarketProvider>
                                </ThemeProvider>
                            </MyKompanContextProvider>
                        </BotDetectorWrapper>
                    </Hydrate>
                </RequestProvider>
            </MsalProvider>
        </AppInsightsContext.Provider>
    );
};

export function reportWebVitals(metric: NextWebVitalsMetric): void {
    console.log('Webvitals', metric.name, Math.round(metric.value * 100) / 100, 'ms');
}

export default App;
