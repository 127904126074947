import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledMaxWidth = styled.div(({ theme }) => ({
    margin: `0 auto`,
    maxWidth: '100%',
    width: '100%',
    [breakpoints.md]: {
        maxWidth: '90vw',
    },
    [breakpoints.xl]: {
        maxWidth: theme.sizes.contentMaxWidth + theme.spaces.gutter * 2,
    },
}));
