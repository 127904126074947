import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { TextVariants } from '.';

type StyledTextProps = {
    variant?: TextVariants;
};

export const StyledText = styled.p<StyledTextProps>(
    () => ({
        img: {
            maxWidth: '100%',
            height: 'auto',
        },
    }),
    switchProp('variant', {
        display1: ({ theme }) => ({
            ...theme.fontVariants.display1,
        }),
        display2: ({ theme }) => ({
            ...theme.fontVariants.display2,
        }),
        display3: ({ theme }) => ({
            ...theme.fontVariants.display3,
        }),
        display4: ({ theme }) => ({
            ...theme.fontVariants.display4,
        }),
        display5: ({ theme }) => ({
            ...theme.fontVariants.display5,
        }),
        display6: ({ theme }) => ({
            ...theme.fontVariants.display6,
        }),
        caption: ({ theme }) => ({
            ...theme.fontVariants.caption,
        }),
        captionSm: ({ theme }) => ({
            ...theme.fontVariants.captionSm,
        }),
        body: ({ theme }) => ({
            ...theme.fontVariants.body,
        }),
        bodySm: ({ theme }) => ({
            ...theme.fontVariants.bodySm,
        }),
    }),
    switchProp('as', {
        i: {
            fontStyle: 'italic',
        },
        em: {
            fontStyle: 'italic',
        },
        strong: ({ theme }) => ({
            fontWeight: theme.fontWeights.bold,
        }),
        b: ({ theme }) => ({
            fontWeight: theme.fontWeights.bold,
        }),
    }),
);
