import React, { Component, ErrorInfo } from 'react';
import { ErrorBoundaryFallback } from './components/ErrorBoundaryFallback';
import { ErrorBoundaryProvider } from './context/ErrorBoundaryProvider';
import { ErrorBoundaryProps, ErrorBoundaryState } from './model/ErrorBoundaryModel';

const initialState = { error: null };

/**
 * Wrap a scope in error boundary to catch errors
 * @example
 * <ErrorBoundary onError={onError} onReset={onReset} fallback={Fallback}>
 *    <WithBugs />
 * </ErrorBoundary>
 *
 * onError = Evoke function on error
 * onReset = Evoke retry or try to fix function when useError onReset is executed
 * fallback = Supply fallback component, defaults to <ErrorBoundaryFallback />
 *
 * Error is available in fallback componenet with useError hook
 *
 * @example
 * const { error, onReset } = useError();
 *
 * <div>Sorry an error occurred {error.message} try again?
 * <button onClick={onReset}>
 *     Try again
 * </button>
 *
 */
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);

        this.onReset = this.onReset.bind(this);
        this.state = initialState;
    }

    static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
        return { error };
    }

    componentDidCatch(error: Error, info: ErrorInfo): void {
        this.props.onError?.(error, info);
    }

    onReset(): void {
        this.props.onReset?.();
        this.setState(initialState);
    }

    render(): JSX.Element {
        const { error } = this.state;

        if (error !== null) {
            const { fallback: Fallback = <ErrorBoundaryFallback /> } = this.props;
            const contextValue = { error, onReset: this.onReset };

            return (
                <ErrorBoundaryProvider contextValue={contextValue}>
                    {typeof Fallback === 'function' ? <Fallback {...contextValue} /> : Fallback}
                </ErrorBoundaryProvider>
            );
        }

        return <>{this.props.children}</>;
    }
}
