import { isBrowser } from '$shared/utils';

export enum StorageKey {
    authToken = 'AUTH_TOKEN',
    redirectSpace = 'REDIRECT_SPACE',
    redirectPath = 'REDIRECT_PATH',
    loginOrigin = 'LOGIN_ORIGIN',
}

export const useStorage = () => {
    const isStorageSupported = () => isBrowser;

    const setStorageKey = (key: string, value: string, isSession?: boolean) => {
        if (!isSession) {
            localStorage.setItem(key, value);
        } else {
            sessionStorage.setItem(key, value);
        }
    };

    const getStorageKey = (key: string, isSession?: boolean) => {
        if (!isSession) {
            return localStorage.getItem(key);
        } else {
            return sessionStorage.getItem(key);
        }
    };

    const clearStorageKey = (key: string, isSession?: boolean) => {
        if (!isSession) {
            return localStorage.removeItem(key);
        } else {
            return sessionStorage.removeItem(key);
        }
    };

    return {
        isStorageSupported,
        setStorageKey,
        getStorageKey,
        clearStorageKey,
    };
};
