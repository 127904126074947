import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Severity } from '~/shared/hooks/useNotification/useNotificationModel';
import { switchProp } from 'styled-tools';
import { breakpoints } from '~/theme';
import { shouldNotForwardProps } from '~/shared/utils/styled';

export const StyledList = styled(motion.ul, {
    shouldForwardProp: shouldNotForwardProps(['scrollDirection']),
})<{ scrollDirection: 'up' | 'down' | null }>(({ theme, scrollDirection }) => ({
    position: 'fixed',
    top: scrollDirection === 'down' ? 0 : 70,
    right: 0,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: 10,
    zIndex: theme.zIndexes['portalHigh'],
    pointerEvents: 'none',
    minHeight: 60,
    width: '100%',

    [breakpoints.sm]: {
        top: scrollDirection === 'up' ? 200 : 25,
        right: 25,
        width: 415,
    },
}));

export const StyledNotification = styled(motion.div)<{ variant: Severity }>(
    ({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        background: theme.colors.black,
        color: theme.colors.white,
        fontSize: theme.fontSizes['xs'],
        lineHeight: 'normal',
        borderRadius: 0,
        zIndex: theme.zIndexes[4],
        overflow: 'hidden',
        pointerEvents: 'auto',
        cursor: 'pointer',

        [breakpoints.sm]: {
            borderRadius: 10,
        },

        'a, span': {
            color: theme.colors.grey60,
            textDecoration: 'underline',
        },
    }),
    switchProp('variant', {
        error: ({ theme }) => ({
            backgroundColor: theme.colors.negative,
        }),
        warning: ({ theme }) => ({
            backgroundColor: theme.colors.neutral,
        }),
        info: ({ theme }) => ({
            backgroundColor: theme.colors.black,
        }),
        success: ({ theme }) => ({
            backgroundColor: theme.colors.positive,
        }),
    }),
);

export const StyleContent = styled.div(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spaces[3],
    padding: theme.spaces[5],
}));

export const StyledCloseButton = styled.button(({ theme: { colors, fontSizes } }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 'auto',
    background: 'none',
    border: 'none',
    color: colors.white,
    padding: 0,
    cursor: 'pointer',
    maxWidth: 580,
    fontSize: fontSizes.lg,
}));
