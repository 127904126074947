import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import {
    LoadingIndicator,
    StyledLoadingIndicatorContainer,
    StyledNavigatingCurtain,
    StyledNavigatingCurtainWaves,
} from '$shared/components';
import { useNavigatingState } from './Hooks';
import { AnimatePresence } from 'framer-motion';
import { Waves } from './components/Waves/Waves';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css'; //styles of nprogress
import { useLocation } from 'react-use';

export const NavigatingSpinner = (): JSX.Element => {
    const router = useRouter();
    const { hash } = useLocation();
    const { navigating, setNavigating, isPopstate, setIsPopstate } = useNavigatingState();
    const [urlKey, setUrlKey] = useState<string | null>(null);
    const animationDuration = 1.7;
    const handleStart = useCallback(
        (url: string) => {
            const newRoutePathname = url.split('?')[0];
            const oldRoutePathname = router.asPath.split('?')[0];

            document.documentElement.style.scrollBehavior = 'auto';

            if (newRoutePathname !== oldRoutePathname) {
                setUrlKey(url);
                NProgress.start();
                const interval = setInterval(() => {
                    if (NProgress.isStarted()) {
                        NProgress.inc(0.05);
                    } else {
                        clearInterval(interval);
                    }
                }, 500);
                setNavigating(true);
            }
        },
        [router],
    );

    const handleComplete = useCallback(() => {
        document.documentElement.style.scrollBehavior = 'auto';

        NProgress.done();

        setNavigating(false);
        setIsPopstate(false);

        if (hash) {
            setTimeout(() => {
                const element = document.getElementById(hash.replace('#', ''));
                element?.scrollIntoView({ behavior: 'smooth' });
            }, 1000);
        }
    }, [hash]);

    const handleHashChangeStart = useCallback(() => {
        document.documentElement.style.scrollBehavior = 'smooth';
    }, []);

    useEffect(() => {
        router.events.on('routeChangeStart', handleStart);
        router.events.on('routeChangeComplete', handleComplete);
        router.events.on('routeChangeError', handleComplete);
        router.events.on('hashChangeStart', handleHashChangeStart);

        return () => {
            router.events.off('routeChangeStart', handleStart);
            router.events.off('routeChangeComplete', handleComplete);
            router.events.off('routeChangeError', handleComplete);
            router.events.off('hashChangeStart', handleHashChangeStart);
        };
    }, [router.asPath]);

    useEffect(() => {
        router.beforePopState((event) => {
            // When user is using back/forward history navigation
            if (event.as != router.asPath) {
                setIsPopstate(true);
            }
            return true;
        });
        NProgress.configure({
            minimum: 0.3,
            trickle: true,
            trickleSpeed: 100,
            easing: 'ease',
            speed: 800,
            showSpinner: false,
        });
    }, [router]);

    return (
        <Fragment>
            {/* <AnimatePresence  exitBeforeEnter={false} initial={true}> */}
            <AnimatePresence mode="sync" initial={true}>
                {navigating && !isPopstate && (
                    <StyledNavigatingCurtain
                        key={urlKey}
                        transition={{
                            type: 'tween',
                            duration: animationDuration,
                            ease: 'circOut',
                        }}
                        initial={{ opacity: 1, y: 0 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 1, y: '-125%' }}
                    >
                        <StyledLoadingIndicatorContainer>
                            <LoadingIndicator />
                        </StyledLoadingIndicatorContainer>

                        <StyledNavigatingCurtainWaves
                            initial={{ transform: 'scaleX(1)' }}
                            animate={{ transform: 'scaleX(3)' }}
                            transition={{
                                type: 'tween',
                                duration: animationDuration,
                                ease: 'circOut',
                            }}
                        >
                            <Waves />
                        </StyledNavigatingCurtainWaves>
                    </StyledNavigatingCurtain>
                )}
            </AnimatePresence>
        </Fragment>
    );
};
