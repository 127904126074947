import { readableColorIsBlack } from 'color2k';
import { theme } from '$theme';

export const getTextColor = (backgroundColor?: string, defaultTextColor = theme.colors.black) => {
    if (!backgroundColor || ['transparent', 'none'].includes(backgroundColor)) {
        return defaultTextColor;
    }

    // Check contrast
    return readableColorIsBlack(backgroundColor) ? theme.colors.black : theme.colors.white;
};
