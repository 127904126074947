import { Translations } from '~/shared/utils/translation';
import { fetcher } from './fetcher';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { isSSR } from '~/shared/utils';
import { QueryParams } from '~/shared/utils/request/utils/buildURL';
import { namespacedKeys } from '~/shared/utils/translation/utils/namespacedKeys';

/**
 * Helper method for requesting translations for a particular culture
 */
export function fetchTranslations({
    culture,
    market,
    preview = false,
}: {
    culture: string;
    market: string;
    preview: boolean;
}) {
    const { API_URL } = publicRuntimeConfig();
    const proxyRoute = `/${culture}/${market}/api/local-proxy/content/translations`.toLowerCase();
    const serverRoute = `${API_URL}/${culture}/${market}/api/content/translations`.toLowerCase();
    const route = isSSR ? serverRoute : proxyRoute;
    const params: QueryParams = {};
    if (preview) {
        params.preview = 'true';
    }

    return fetcher<Translations>(route, params)
        .then((res) => res.json())
        .then((data) => namespacedKeys(data));
}
