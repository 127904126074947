import { ImageLoaderProps } from 'next/image';

export const defaultLoader = ({ src, ...rest }: ImageLoaderProps) => {
    if (src == null) return '';
    let absoluteSrc = src;
    if (src.startsWith('//')) {
        absoluteSrc = src.replace('//', 'https://');
    }
    const url = new URL(absoluteSrc);

    if (src.indexOf('ctfassets') === -1) {
        Object.entries(rest).forEach(([key, value]) => {
            if (value !== undefined) {
                url.searchParams.append(key, `${value}`);
            }
        });
    }

    return url.toString();
};
