import React from 'react';
import Script from 'next/script';

type GoogleTagManagerProps = {
    googleTagManagerId: string;
};

export const GoogleTagManager = ({ googleTagManagerId }: GoogleTagManagerProps) => {
    return (
        <>
            <Script
                id={'GoogleDatalayerInit'}
                dangerouslySetInnerHTML={{
                    __html: `
                        window.dataLayer = window.dataLayer || [];
                    `,
                }}
            />
            <Script
                id={'GoogleTagManagerScript'}
                strategy={'lazyOnload'}
                async={true}
                dangerouslySetInnerHTML={{
                    __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','${googleTagManagerId}');`,
                }}
            />
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}" height="0" width="0" style="display: none; visibility: hidden;" /></iframe>`,
                }}
            ></noscript>
        </>
    );
};
