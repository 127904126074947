import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const StyledNavigatingCurtain = styled(motion.div)(({ theme }) => ({
    backgroundColor: theme.colors.white,
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: theme.zIndexes.portalHigh,
}));

export const StyledNavigatingCurtainWaves = styled(motion.div)(({ theme }) => ({
    position: 'absolute',
    top: '100%',
    left: 0,
    marginTop: -4,
    width: '100%',
    transformOrigin: 'center top',
    color: theme.colors.white,
}));

export const StyledLoadingIndicatorContainer = styled.div(({ theme }) => ({
    position: 'fixed',
    top: '50%',
    left: '50%',
    zIndex: theme.zIndexes.portalHigh,
    transform: 'translate(-50%, -50%)',
}));
