import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const useScrollDirection = () => {
    const router = useRouter();
    const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(null);

    useEffect(() => {
        let lastScrollY = window.pageYOffset;

        const updateScrollDirection = () => {
            const scrollY = window.pageYOffset;
            const direction = scrollY > lastScrollY ? 'down' : 'up';

            // Fix scroll being triggered when openeing or closing modals.
            if (direction == 'up' && scrollY == 0) return;

            if (
                direction !== scrollDirection &&
                (scrollY - lastScrollY > 2 || scrollY - lastScrollY < -2)
            ) {
                setScrollDirection(direction);
            }
            lastScrollY = scrollY > 0 ? scrollY : 0;
        };
        const forceScrollUp = () => {
            setScrollDirection('up');
        };

        window.addEventListener('scroll', updateScrollDirection); // add event listener
        router.events.on('routeChangeComplete', forceScrollUp);
        return () => {
            window.removeEventListener('scroll', updateScrollDirection); // clean up
            router.events.off('routeChangeComplete', forceScrollUp);
        };
    }, [scrollDirection]);

    return scrollDirection;
};
