import { createContext } from 'react';
import { Modules } from '~/lib/data-contract';

export type ModuleContextType<T = Modules> = {
    index: number;
    total: number;
    element: T | Record<string, string>;
};

export const ModuleContext = createContext({ index: 1, total: 1, element: {} });
