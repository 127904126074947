export const japaneseFontsFace = `
@font-face {
    font-family: 'Zen Maru Gothic';
    src: url('/assets/fonts/zenMaru/ZenMaruGothic-Black.woff2') format('woff2'),
        url('/assets/fonts/zenMaru/ZenMaruGothic-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Zen Maru Gothic';
    src: url('/assets/fonts/zenMaru/ZenMaruGothic-Bold.woff2') format('woff2'),
        url('/assets/fonts/zenMaru/ZenMaruGothic-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Zen Maru Gothic';
    src: url('/assets/fonts/zenMaru/ZenMaruGothic-Light.woff2') format('woff2'),
        url('/assets/fonts/zenMaru/ZenMaruGothic-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Zen Maru Gothic';
    src: url('/assets/fonts/zenMaru/ZenMaruGothic-Regular.woff2') format('woff2'),
        url('/assets/fonts/zenMaru/ZenMaruGothic-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
/* 
@font-face {
    font-family: 'Zen Maru Gothic';
    src: url('/assets/fonts/zenMaru/ZenMaruGothic-Medium.woff2') format('woff2'),
        url('/assets/fonts/zenMaru/ZenMaruGothic-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
 */
`;
