import React from 'react';
import { IMarket } from '../model/marketModel';

export const MarketContext = React.createContext<IMarket>({} as IMarket);

interface IMarketProviderProps {
    value: IMarket;
}

export const MarketProvider = ({
    children,
    value,
}: React.PropsWithChildren<IMarketProviderProps>) => {
    return <MarketContext.Provider value={value}>{children}</MarketContext.Provider>;
};
