import { create } from 'zustand';

export type NavigatingState = {
    navigating: boolean;
    setNavigating: (navigating: boolean) => void;
    isPopstate: boolean;
    setIsPopstate: (isPopstate: boolean) => void;
};

export const useNavigatingState = create<NavigatingState>((set) => ({
    navigating: false,
    setNavigating: (navigating: boolean): void => {
        set(() => ({
            navigating: navigating,
        }));
    },
    isPopstate: false,
    setIsPopstate: (isPopstate: boolean): void => {
        set(() => ({
            isPopstate: isPopstate,
        }));
    },
}));
