export const fonts = {
    headline: 'VAG Rounded Std, Arial, sans-serif, system-ui',
    body: 'VAG Rounded Std, Arial, sans-serif, system-ui',
    mono: 'monospace',
};

export const japaneseFonts = {
    headline: 'Zen Maru Gothic, serif, system-ui',
    body: 'Zen Maru Gothic, serif, system-ui',
    mono: 'monospace',
};
