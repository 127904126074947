import { ImageLoaderProps } from 'next/image';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
const { MEDIA_BASE_URL, DEPRECATED_MEDIA_API_URL } = publicRuntimeConfig();

const normalizeSrc = (src: string) => {
    return src[0] === '/' ? src.slice(1) : src;
};

// Due to limitations on image loaders in NextJS, we need to add cloudflare params as custom parameters and extract them in the loader.
const getCloudflareParams = (src: string): string[] => {
    try {
        const url = new URL(src, 'https://example.com');
        const params = new URLSearchParams(url.search);
        const cfParams = params.get('cf');
        return cfParams ? cfParams.split('&') : [];
    } catch (_e) {
        console.error(
            'cloudflareLoaderParamError: failed to parse cloudflare params from src:',
            src,
        );
        return [];
    }
};

const getSrcAsRelative = (url: string) => {
    const urlObj = new URL(url, 'https://example.com');
    const search = urlObj.search ? `${urlObj.search}` : '';
    const hash = urlObj.hash ? `${urlObj.hash}` : '';
    return `${urlObj.pathname}${search}${hash}`;
};
// Deprecated media api can work exactly the same as the cloudflare loader, but with a different base url.
export const deprecatedMediaApiLoader = (props: ImageLoaderProps) => {
    const cloudFlareString = kompanCloudflareLoader(props);
    return cloudFlareString.replace(MEDIA_BASE_URL, DEPRECATED_MEDIA_API_URL);
};

export const kompanCloudflareWithoutRelativeLoader = (props: ImageLoaderProps) => {
    return kompanCloudflareLoader({ ...props, asRelative: false });
};

export const kompanCloudflareLoader = ({
    src,
    width,
    quality = 85,
    asRelative = true,
}: ImageLoaderProps & { asRelative?: boolean }) => {
    const params = [`width=${width}`];
    const cfParams = getCloudflareParams(src);
    const modifiedSource = asRelative ? getSrcAsRelative(src) : src;
    params.push(`format=auto`);
    params.push(`quality=${quality}`);
    if (cfParams.length) {
        params.push(...cfParams);
    }
    const paramsString = encodeURIComponent(params.join(','));

    return `${MEDIA_BASE_URL}/cdn-cgi/image/${paramsString}/${normalizeSrc(modifiedSource)}`;
};
