import { ITelemetryItem } from '@microsoft/applicationinsights-web';

export const telemetryRegexList: RegExp[] = [
    // Known resizeObserver bug: https://stackoverflow.com/a/50387233
    /ResizeObserver loop/i,
    // Unkown error with no
    /ErrorEvent: Script error./i,
    // Issue from GTM
    /fbq is not defined/i,
    // Some error caused by ReactDom when using onScrollHandlers. Can't reproduce.
    /Uncaught TypeError: Cannot read properties of undefined (reading 'defaultPrevented')/i,
    // Chrome extensions errors
    /chrome-extension:\/\//i,
    // Error caused by third party avast antivirus. Not part of codebase.
    /_avast_submit/i,
    // pconbox 3d configurator
    /pconbox\.kompan\.com/i,
    /\/assets\/wcf/i,
    // Known tracking & marketing events
    /tracking\.kompan\.com/i,
    /ct\.pinterest\.com/i,
    /ads\.linkedin\.com/i,
    /policy\.app\.cookieinformation\.com/i,
];

export const applicationInsightsTelemetryFilter = (envelope: ITelemetryItem) => {
    const data = envelope.data ?? {};
    const baseData = envelope.baseData ?? {};
    return !Object.values({ ...data, ...baseData }).find(
        (field) => typeof field === 'string' && telemetryRegexList.some((x) => x.test(field)),
    );
};
