import React, { useEffect } from 'react';
import { StyleContent, StyledCloseButton, StyledNotification } from '../styled';
import { NotificationOptions } from '~/shared/hooks/useNotification/useNotificationModel';
import Close from '$icons/close.svg';
import { useTranslation } from '~/shared/utils/translation';
import { useTimeoutFn } from 'react-use';

type Props = NotificationOptions & {
    onDismiss: () => void;
    isMobile: boolean;
};

const variants = {
    pre: { opacity: 1, x: 600 },
    visible: { opacity: 1, x: 0 },
};

const variantsMobile = {
    pre: { opacity: 1, y: -200, x: 0 },
    visible: { opacity: 1, y: 0, x: 0 },
};

const gentle = {
    type: 'spring',
    stiffness: 100,
    damping: 15,
    mass: 1,
    duration: 0.6,
};

export const Notification = ({
    text,
    onDismiss,
    onClick,
    severity = 'error',
    duration,
    isMobile,
}: Props) => {
    const { translate } = useTranslation();
    const [, cancel, restart] = useTimeoutFn(onDismiss, duration);

    useEffect(() => {
        if (!duration) {
            cancel();
        }
    }, []);

    return (
        <StyledNotification
            layout
            variant={severity}
            variants={isMobile ? variantsMobile : variants}
            initial="pre"
            animate="visible"
            exit="pre"
            aria-live="polite"
            role="button"
            tabIndex={0}
            onClick={onClick || onDismiss}
            onFocus={cancel}
            onMouseEnter={cancel}
            onMouseLeave={duration ? restart : undefined}
            onTouchStart={cancel}
            onTouchEnd={duration ? restart : undefined}
            transition={gentle}
        >
            <StyleContent>
                {text}
                <StyledCloseButton onClick={onDismiss}>
                    <Close title={translate('Kompan.Shared.Close')} />
                </StyledCloseButton>
            </StyleContent>
        </StyledNotification>
    );
};
