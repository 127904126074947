import dynamic from 'next/dynamic';
import React from 'react';
import { Modules } from '~/lib/data-contract';
import { ErrorBoundary } from '~/shared/utils/errorBoundary/ErrorBoundary';
import { ErrorFallbackContent } from '~/templates/blocks/components/ErrorFallbackContent/ErrorFallbackContent';
import { MyKompanSpecificModules } from '~/lib/data-contract/manual-overwrite/api-model';
import { InViewContextWrapper } from '$shared/utils/in-view';

export const M10Hero = dynamic(() => import('../M10Hero'));
export const M60USP = dynamic(() => import('../M60USP'));
export const M70Media = dynamic(() => import('../M70Media'));
export const M75MediaText = dynamic(() => import('../M75MediaText'));
export const M43WebinarForm = dynamic(() => import('../M43WebinarForm'));
export const M80MediaTextBundle = dynamic(() => import('../M80MediaTextBundle'));
export const M85Details = dynamic(() => import('../M85Details'));
export const M86HighlightList = dynamic(() => import('../M86HighlightList'));
export const M90Quote = dynamic(() => import('../M90Quote'));
export const M100RichText = dynamic(() => import('../M100RichText'));
export const M110TenderText = dynamic(() => import('../M110TenderText'));
export const M120Accordion = dynamic(() => import('../M120Accordion'));
export const M140ProductList = dynamic(() => import('../M140ProductsList'));
export const M150LargeText = dynamic(() => import('../M150LargeText'));
export const M89RelevantContent = dynamic(() => import('../M89RelevantContent'));
export const M30ProductCarousel = dynamic(() => import('../M30ProductCarousel'));
export const M31PopularProducts = dynamic(() => import('../M31PopularProducts'));
export const M32SimilarProducts = dynamic(() => import('../M32SimilarProducts'));
export const M33SimilarStyleProducts = dynamic(() => import('../M33SimilarStyleProducts'));
export const M34SimilarProductsToLastProductViewed = dynamic(
    () => import('../M34SimilarProductsToLastProductViewed'),
);

export const M40ContactForm = dynamic(() => import('../M40ContactForm'));
export const M44GeneralKCSForm = dynamic(() => import('../M44GeneralKCSForm'));
export const M41NewsletterForm = dynamic(() => import('../M41NewsletterForm'));
export const M170JobMatchProfile = dynamic(() => import('../M170JobMatchProfile'));
export const M180CookiePolicy = dynamic(() => import('../M180CookiePolicy'), { ssr: false });
export const M42CatalogueForm = dynamic(() => import('../M42CatalogueForm'));
export const MyKompanLogin = dynamic(() => import('~/features/my-kompan-login'));
export const MyKompanLoginRedirect = dynamic(() => import('~/features/authentication/components'));
export const MyKompanSalesDocument = dynamic(
    () => import('~/features/quotes-overview/components'),
    { ssr: false },
);
export const MyKompanFeaturePlanner = dynamic(() => import('$features/featurePlanner/components'), {
    ssr: false,
});
export const MyKompanDashboardModule = dynamic(() => import('$features/MyKompanDashboard'), {
    ssr: false,
});
export const MyKompanProfileModule = dynamic(() => import('$features/MyKompanProfile'), {
    ssr: false,
});
export const MyKompanMySpacesModule = dynamic(() => import('$features/MyKompanMySpaces'), {
    ssr: false,
});
export const MyKompanAcademyModule = dynamic(() => import('$features/MyKompanAcademy'), {
    ssr: false,
});
export const M160GoogleMap = dynamic(() => import('$templates/blocks/components/M160Map'));
export const StaticErrorPageModule = dynamic(() => import('../StaticErrorPageModule'));
export const Static404PageModule = dynamic(() => import('../Static404PageModule'));

type DynamicBlockProps = {
    element: Modules;
    index?: number;
};

export const DynamicBlock = ({ element }: DynamicBlockProps) => {
    return (
        <ErrorBoundary fallback={<ErrorFallbackContent />}>
            <InViewContextWrapper>{getType(element)}</InViewContextWrapper>
        </ErrorBoundary>
    );
};

const getType = (element: Modules | MyKompanSpecificModules) => {
    switch (element.type) {
        case 'm10HeroModule':
            return <M10Hero {...element} />;

        case 'm30ProductCarouselModule':
            return <M30ProductCarousel {...element} />;

        case 'm31PopularProductsModule':
            return <M31PopularProducts {...element} />;

        case 'm32SimilarProductsModule':
            return <M32SimilarProducts {...element} />;

        case 'm33SimilarStyleProductsModule':
            return <M33SimilarStyleProducts {...element} />;

        case 'm34SimilarProductsToLastProductViewedModule':
            return <M34SimilarProductsToLastProductViewed {...element} />;

        case 'm40ContactFormModule':
            return <M40ContactForm {...element} />;

        case 'm44GeneralKCSFormModule':
            return <M44GeneralKCSForm {...element} />;

        case 'm41NewsletterFormModule':
            return <M41NewsletterForm {...element} />;

        case 'm42CatalogueFormModule':
            return <M42CatalogueForm {...element} />;

        case 'm43WebinarFormModule':
            return <M43WebinarForm {...element} />;

        case 'm60UspModule':
            return <M60USP {...element} />;

        case 'm70MediaModule':
            return <M70Media {...element} />;

        case 'm75MediaAndTextModule':
            return <M75MediaText {...element} />;

        case 'm80MediaAndTextBundleModule':
            return <M80MediaTextBundle {...element} />;

        case 'm85DetailsModule':
            return <M85Details {...element} />;

        case 'm86HighlightListModule':
            return <M86HighlightList {...element} />;

        case 'm89RelevantContentModule':
            return <M89RelevantContent {...element} />;

        case 'm90QuoteModule':
            return <M90Quote {...element} />;

        case 'm100RichTextModule':
            return <M100RichText {...element} />;

        case 'm110TenderTextModule':
            return <M110TenderText {...element} />;

        case 'm150LargeText':
            return <M150LargeText {...element} />;

        case 'm120AccordionListModule':
            return <M120Accordion {...element} />;

        case 'm140ProductListModule':
            return <M140ProductList {...element} />;

        case 'm160MapModule':
            return <M160GoogleMap {...element} />;

        case 'm170JobMatchProfileModule':
            return <M170JobMatchProfile {...element} />;

        case 'm180CookiePolicyModule':
            return <M180CookiePolicy {...element} />;

        case 'myKompanLoginModule':
            return <MyKompanLogin {...element} />;

        case 'myKompanLoginRedirectModule':
            return <MyKompanLoginRedirect />;

        case 'myKompanSalesDocumentModule':
            return <MyKompanSalesDocument />;

        case 'myKompanFeaturePlannerModule':
            return <MyKompanFeaturePlanner />;

        case 'myKompanDashboardModule':
            return <MyKompanDashboardModule {...element} />;

        case 'myKompanProfileModule':
            return <MyKompanProfileModule {...element} />;

        case 'myKompanMySpacesModule':
            return <MyKompanMySpacesModule {...element} />;

        case 'myKompanAcademyModule':
            return <MyKompanAcademyModule {...element} />;

        case 'staticErrorPageModule':
            return <StaticErrorPageModule />;

        case 'static404PageModule':
            return <Static404PageModule />;

        default:
            return null;
    }
};
