import { ComponentProps, DetailedHTMLProps, forwardRef, HTMLAttributes, Ref } from 'react';
import { StyledText } from './styled';

export type TextVariants =
    | 'display1'
    | 'display2'
    | 'display3'
    | 'display4'
    | 'display5'
    | 'display6'
    | 'body'
    | 'bodySm'
    | 'caption'
    | 'captionSm';

export type TextProps = ComponentProps<typeof StyledText>;

/**
 * Override rendered markup with `as` attribute. For instance:
 *
 * ```tsx
 * <Text as="span">An span in markup.</Text>
 * ```
 */

export const Text = forwardRef(
    (
        props: DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> &
            TextProps,
        ref: Ref<HTMLParagraphElement>,
    ) => {
        return <StyledText {...props} variant={props.variant ?? 'body'} ref={ref} />;
    },
);

Text.displayName = 'Text';
