import React, { ForwardedRef, ReactNode, useContext, useEffect, useState } from 'react';
import { ModuleSpacing } from '~/lib/data-contract';
import { MaxWidth } from '~/shared/components';
import { Gutter } from '~/shared/components/Gutter';
import { useModuleTheme } from '../../hooks/useModuleTheme';
import { StyledModule, StyledModuleWrapper } from './styled';
import { useInView } from 'react-cool-inview';
import { useScrollDirection } from '$shared/hooks/useScrollDirection';
import { isElementInViewport } from '$shared/utils';
import { InViewContext } from '$shared/utils/in-view';
import { useModule } from '../../hooks/useModule';

export type ModuleContainerProps = {
    /**
     * Adds a solid background color to the entire module space.
     * Edge to edge.
     */
    backgroundColor?: string;

    spacingTop?: ModuleSpacing['spacingTop'];
    spacingBottom?: ModuleSpacing['spacingBottom'];

    /**
     * Prevents capping the width to the max content widht
     */
    fullWidth?: boolean;

    /**
     * Add side gutters to the module. Usefull if the content
     * is not intended to go full width on mobile. eg. text modules
     */
    hasGutter?: boolean;

    children: ReactNode;

    pageElementIndex?: number;

    disableModuleAnimation?: boolean;

    anchorPointReference?: string;
};

export const ModuleContainer = React.forwardRef(
    (
        {
            fullWidth,
            children,
            spacingTop,
            spacingBottom,
            hasGutter,
            backgroundColor: initialBackgroundColor,
            pageElementIndex,
            disableModuleAnimation,
            anchorPointReference,
        }: ModuleContainerProps,
        ref?: ForwardedRef<HTMLDivElement>,
    ): JSX.Element => {
        const [elementRef, setElementRef] = useState<HTMLElement | null>(null);
        const { inView: isInView, setInView } = useContext(InViewContext);
        const scrollDirection = useScrollDirection();
        const { backgroundColor } = useModuleTheme({
            backgroundColor: initialBackgroundColor,
        });
        const { total, index } = useModule();

        const content = hasGutter ? <Gutter children={children} /> : children;

        const { observe } = useInView({
            unobserveOnEnter: true,
            onEnter: () => setInView(),
        });

        const inView = disableModuleAnimation || pageElementIndex === 0 || isInView;

        useEffect(() => {
            if (!elementRef) return;

            if (inView) {
                if (!isInView) {
                    setInView();
                }
            } else {
                const timeoutRef = setTimeout(() => {
                    if (isElementInViewport(elementRef)) {
                        setInView();
                    }
                }, 1000);

                return () => clearTimeout(timeoutRef);
            }
        }, [inView, isInView, elementRef]);

        return (
            <StyledModuleWrapper
                ref={(ref) => {
                    observe(ref);
                    setElementRef(ref);
                }}
                id={anchorPointReference ?? undefined}
                total={total}
                index={index}
            >
                <StyledModule
                    backgroundColor={backgroundColor}
                    spacingBottom={spacingBottom}
                    spacingTop={spacingTop}
                    ref={ref}
                    inView={inView}
                    scrollDown={scrollDirection === 'down'}
                    disableAnimation={disableModuleAnimation || index <= 1}
                >
                    {fullWidth ? content : <MaxWidth children={content} />}
                </StyledModule>
            </StyledModuleWrapper>
        );
    },
);

ModuleContainer.displayName = 'ModuleContainer';
