import NextImage, { ImageProps as NextImageProps } from 'next/legacy/image';
import { useContext, useEffect, useMemo, useState, memo } from 'react';
import {
    deprecatedMediaApiLoader,
    kompanCloudflareLoader,
    kompanCloudflareWithoutRelativeLoader,
} from './loaders/cloudflareLoader';
import { defaultLoader } from './loaders/defaultLoader';
import { StyledImageWrapper } from './styled';
import { contentfulLoader } from '$shared/components/Image/loaders/contentfulLoader';
import { useInView } from 'react-cool-inview';
import { isElementInViewport } from '$shared/utils';
import throttle from 'lodash/throttle';
import { BOT_DETECTOR_CONTEXT } from '$shared/utils/bot-detector';
import { CSSObject } from '@emotion/react';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import React from 'react';

export type ImageProps = NextImageProps & {
    disableSkeleton?: boolean;
    isDamImage?: boolean;
    imageFocalPoint?: {
        x: number;
        y: number;
    };
    disableAnimation?: boolean;
    css?: CSSObject;
};

// @see https://github.com/vercel/next.js/blob/canary/packages/next/client/image.tsx
type OnLoadingComplete = {
    naturalWidth: number;
    naturalHeight: number;
};

const { DEPRECATED_MEDIA_API_URL, MEDIA_BASE_URL } = publicRuntimeConfig();

export const _Image = ({
    src = '',
    width: initialWidth = 0,
    height: initialHeight = 0,
    layout = 'responsive',
    disableSkeleton = false,
    alt = '',
    imageFocalPoint,
    disableAnimation = false,
    priority,
    ...rest
}: ImageProps) => {
    const [elementRef, setElementRef] = useState<HTMLElement | null>(null);
    const [isInView, setIsInView] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(true);

    /**
     * @TODO Look into using custom hook to cache src dimensions
     * to prevent additional layout shifts for repeated images
     */
    const [width, setWidth] = useState(initialWidth);
    const [height, setHeight] = useState(initialHeight);
    const [focalpointValue, setFocalpointValue] = useState('center');
    const setDimensions = layout !== 'fill';
    const isBot = useContext(BOT_DETECTOR_CONTEXT);

    const getLoader = (src: ImageProps['src']) => {
        const isStatic = !/^http?s:\/\//.test(src.toString());
        const isSvg = typeof src === 'string' && src.toString().includes('.svg');
        const isContentful = typeof src === 'string' && src.toString().indexOf('ctfassets') > -1;
        if (src.toString().includes('pconbox')) return kompanCloudflareWithoutRelativeLoader;
        if (isStatic) return undefined;
        if (isSvg) return defaultLoader;
        if (isContentful) return contentfulLoader;

        const isDeprecated =
            typeof src === 'string' &&
            ['media.mykompan', 'media.kompan'].some((url) => src.toString().includes(url));

        // Deprecated media API
        if (DEPRECATED_MEDIA_API_URL && isDeprecated) return deprecatedMediaApiLoader;

        // Kompan blob through cloudflare
        if (typeof src === 'string' && src.includes(MEDIA_BASE_URL)) {
            return kompanCloudflareLoader;
        }

        // Fallback should be undefined so next/image can handle unsupported loaders.
        return undefined;
    };

    const onLoadHandler = ({ naturalWidth, naturalHeight }: OnLoadingComplete) => {
        const noDimensions = !width || !height;

        /**
         * Setting naturalWidth and naturalHeight on the image element
         * if non provided.
         * isLoading ensures only initial render.
         * Updating dimensions on the image will retrigger onLoadingComplete.
         */
        if (isLoading && noDimensions && setDimensions) {
            setWidth(naturalWidth);
            setHeight(naturalHeight);
        }
        if (isLoading) {
            setIsLoading(false);
        }
    };

    const { observe } = useInView({
        unobserveOnEnter: true,
        rootMargin: '50px',
        onEnter: () => {
            setIsInView(true);
        },
        onChange: (e) => {
            // Sometimes onEnter doesn't trigger and we need to listen for continuous onChange
            if (e.inView) {
                setIsInView(true);
            }
        },
    });

    useEffect(() => {
        if (!elementRef) return;

        const timeout = setTimeout(() => {
            if (isElementInViewport(elementRef)) {
                setIsInView(true);
            }
        }, 1000);
        return () => {
            timeout && clearTimeout(timeout);
        };
    }, [elementRef]);

    const getSafeOffset = (value: number) => {
        return Math.round(Math.min(100, Math.max(0, value)));
    };

    const calculateFocalpointValue = () => {
        let objectPosition = `center`;
        if (!width || !height || !imageFocalPoint || !elementRef || isLoading)
            return objectPosition;
        const imageElement = elementRef?.querySelector('img');
        if (!imageElement) return objectPosition;

        const [imageW, imageH] = [parseInt(`${initialWidth}`), parseInt(`${initialHeight}`)];
        const { width: cropWidth, height: cropHeight } = imageElement.getBoundingClientRect();
        if (!width || !height) return objectPosition;

        const fpX = imageFocalPoint.x / imageW;
        const fpY = imageFocalPoint.y / imageH;
        const cropRatio = cropWidth / cropHeight;
        const imageRatio = imageW / imageH;
        const isHorizontalCrop = cropRatio < imageRatio;
        // If cropRatio is smaller than imageRatio, we know to crop on the horizontal plane, i.e. remove from sides.
        // Else crop on vertical plane, i.e. remove from top and bottom
        if (isHorizontalCrop) {
            const scaleRatio = cropHeight / imageH;
            const scaledWidth = imageW * scaleRatio; // Width of image when width is scaled to container
            const totalTravelWidth = scaledWidth - cropWidth; // Total pixel distance the image can move in container
            const focalCenter = scaledWidth * fpX; // Distance to focalpoint
            const halfContainer = cropWidth / 2;
            const focalX = focalCenter - halfContainer;
            const objectPosX = (focalX / totalTravelWidth) * 100;
            const safeObjectPostX = getSafeOffset(objectPosX);

            objectPosition = `${safeObjectPostX}% 50%`;
        } else {
            const scaleRatio = cropWidth / imageW;
            const scaledHeight = imageH * scaleRatio; // Height of image when width is scaled to container
            const totalTravelHeight = scaledHeight - cropHeight; // Total pixel distance the image can move in container
            const focalCenter = scaledHeight * fpY; // Distance to focalpoint
            const halfContainer = cropHeight / 2;
            const focalY = focalCenter - halfContainer;
            const objectPosY = (focalY / totalTravelHeight) * 100;
            const safeObjectPosY = getSafeOffset(objectPosY);

            objectPosition = `50% ${safeObjectPosY}%`;
        }

        setFocalpointValue(objectPosition);
    };

    useEffect(() => {
        const imageElement = elementRef?.querySelector('img');
        if (!imageElement || layout != 'fill' || !imageFocalPoint?.x || isLoading) {
            return;
        }
        // Limnit calcuation to once per 250ms
        const debouncedCalc = throttle(calculateFocalpointValue, 250, {
            trailing: true,
        });

        const observer = new ResizeObserver(() => {
            debouncedCalc();
        });
        observer.observe(imageElement);
        calculateFocalpointValue();
        const timeout = setTimeout(() => {
            calculateFocalpointValue();
        }, 0);

        return () => {
            observer && observer.disconnect();
            timeout && clearTimeout(timeout);
        };
    }, [elementRef, layout, imageFocalPoint?.x, src, isLoading]);

    // Make sure src won't break loader
    const resolvedSrc = useMemo(() => {
        if (typeof src !== 'string') return src;

        if (src.startsWith('//')) {
            return src.replace('//', 'https://');
        }
        return src;
    }, [src]);

    return (
        <StyledImageWrapper
            ref={(ref) => {
                observe(ref);
                setElementRef(ref);
            }}
            inView={isInView || !isLoading}
            loading={isLoading}
            disableSkeleton={disableSkeleton}
            disableAnimation={disableAnimation || isBot}
            layout={layout}
        >
            <NextImage
                // Next 14 and Emotion 11 has a bug with className hydration mismatch. The component works as expected but throws the error.
                suppressHydrationWarning
                loader={getLoader(src)}
                src={resolvedSrc}
                loading={priority ? 'eager' : 'lazy'}
                priority={priority}
                onLoadingComplete={onLoadHandler}
                width={width}
                height={height}
                layout={layout}
                alt={alt}
                objectPosition={rest?.objectPosition ?? focalpointValue}
                {...rest}
            />
        </StyledImageWrapper>
    );
};

export const Image = memo(_Image);
