import { ThemeProvider as EmotionThemeProvider, ThemeProviderProps } from '@emotion/react';
import { useMemo } from 'react';
import { theme } from '../theme';
import { getLanguageFontVariants, getLanguageFonts } from '../utils/getLanguagesFonts';

type ThemeProviderP = Omit<ThemeProviderProps, 'theme'> & {
    direction?: 'rtl' | 'ltr';
    language: string;
};

export const ThemeProvider = (props: ThemeProviderP) => {
    const { direction, language, ...rest } = props;

    const dynamicTheme = useMemo(() => {
        const fontVariants = getLanguageFontVariants(language);
        const fonts = getLanguageFonts(language);

        return {
            ...theme,
            direction,
            fonts,
            fontVariants,
        };
    }, [language, direction]);

    return <EmotionThemeProvider theme={dynamicTheme} {...rest} />;
};
