import styled from '@emotion/styled';
import { switchProp } from 'styled-tools';
import { breakpoints } from '~/theme';

// TODO - create a proper content wrapper
export const StyledGutter = styled.div<{
    flexAlignment?: 'center';
    flexFlow?: 'column';
    flexJustify?: 'spaceBetween' | 'flexEnd';
}>(
    ({ theme }) => ({
        paddingLeft: theme.spaces.mobileGutter,
        paddingRight: theme.spaces.mobileGutter,
        [breakpoints.sm]: {
            paddingLeft: theme.spaces.gutter,
            paddingRight: theme.spaces.gutter,
        },
    }),
    switchProp('flexAlignment', {
        center: () => ({
            display: 'flex',
            justifyContent: 'center',
        }),
    }),
    switchProp('flexFlow', {
        column: () => ({
            display: 'flex',
            flexFlow: 'column wrap',
            justifyContent: 'center',
            alignItems: 'center',
        }),
    }),
    switchProp('flexJustify', {
        spaceBetween: () => ({
            display: 'flex',
            justifyContent: 'space-between',
        }),
        flexEnd: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
        }),
    }),
);
