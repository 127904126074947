import { SpringOptions } from '~/theme/model/themeModel';

export const easeSmooth = 'cubic-bezier(0.35, 0, 0.15, 1)';
export const easeEqual = 'cubic-bezier(0.62, 0, 0.28, 1)';
export const easeOutCubic = 'cubic-bezier(0.215, 0.61, 0.355, 1)';
export const easeOutCirc = 'cubic-bezier(0.075, 0.82, 0.165, 1)';
export const easeOutExpo = 'cubic-bezier(0.190, 1.000, 0.220, 1.000)';
export const easeInOutQuad = 'cubic-bezier(0.45, 0, 0.55, 1)';
export const easeOutQuint = 'cubic-bezier(0.22, 1, 0.36, 1)';
export const easeInQuint = 'cubic-bezier(0.64, 0, 0.78, 0)';
export const timingShort = '150ms';
export const timingMedium = '250ms';
export const timingLong = '400ms';
export const timingLonger = '600ms';
export const timingLongest = '1000ms';
export const timingExtreme = '6000ms';

export const springDefault: SpringOptions = {
    stiffness: 150,
    damping: 15,
};

export const buttonAnimationSpace = 5;
