import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledWaveContainer = styled.div(() => ({
    height: 50,
    overflow: 'hidden',
    [breakpoints.sm]: {
        height: 80,
    },
    [breakpoints.md]: {
        height: 120,
    },
    [breakpoints.lg]: {
        height: 150,
    },

    svg: {
        filter: 'drop-shadow(0 0.2rem 0.55rem rgba(0, 0, 0, 0.1))',
    },
}));
