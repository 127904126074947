import { ImageLoaderProps } from 'next/image';

const normalizeSrc = (src: string) => {
    return src[0] === '/' ? src.slice(1) : src;
};

export const contentfulLoader = ({ src, width, quality }: ImageLoaderProps) => {
    const params = [`w=${width}`];
    if (quality) {
        params.push(`q=${quality}`);
        params.push(`fm=webp`);
    } else {
        params.push(`q=90`);
        params.push(`fm=webp`);
    }
    const paramsString = params.join('&');

    return `${normalizeSrc(src)}?${paramsString}`;
};
