import React from 'react';

type MyKompanContextProviderPRops = { isMyKompan: boolean };

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const MyKompanContext = React.createContext<MyKompanContextProviderPRops>(undefined!);

export const MyKompanContextProvider = ({
    children,
    ...otherProps
}: React.PropsWithChildren<MyKompanContextProviderPRops>) => {
    return (
        <MyKompanContext.Provider value={{ ...otherProps }}>{children}</MyKompanContext.Provider>
    );
};
