// NOTE: Is this the best way to use colors from figma?
import * as colorToken from './design-token-color';
export const white100 = '#FFFFFF';
export const white = white100;

export const black100 = '#000000';
export const black80 = '#333333';
export const black = black100;

export const red100 = colorToken.red100;
export const red80 = colorToken.red80;
export const red60 = colorToken.red60;
export const red40 = colorToken.red40;
export const red20 = colorToken.red20;
export const red10 = colorToken.red10;
export const red = red100;

export const grey100 = colorToken.grayscale100;
export const grey80 = colorToken.grayscale80;
export const grey60 = colorToken.grayscale60;
export const grey40 = colorToken.grayscale40;
export const grey30 = colorToken.grayscale30;
export const grey20 = colorToken.grayscale20;
export const grey10 = colorToken.grayscale10;
export const grey05 = colorToken.grayscale5;
export const grey = grey40;

export const green100 = colorToken.green100;
export const green80 = colorToken.green80;
export const green60 = colorToken.green60;
export const green40 = colorToken.green40;
export const green20 = colorToken.green20;
export const green10 = colorToken.green10;
export const green = green100;

export const yellow100 = colorToken.yellow100;
export const yellow80 = colorToken.yellow80;
export const yellow60 = colorToken.yellow60;
export const yellow40 = colorToken.yellow40;
export const yellow20 = colorToken.yellow20;
export const yellow10 = colorToken.yellow10;
export const yellow = yellow60;

export const blue100 = colorToken.blue100;
export const blue80 = colorToken.blue80;
export const blue60 = colorToken.blue60;
export const blue40 = colorToken.blue40;
export const blue20 = colorToken.blue20;
export const blue10 = colorToken.blue10;
export const blue = blue20;

export const primary = red;
export const secondary = grey;
export const positive = green;
export const neutral = blue60;
export const negative = red;

/*Recommendations flow Colors */

export const rfBlue80 = colorToken.rfBlue80;
export const rfBlue = colorToken.rfBlue;
export const rfOrange10 = colorToken.rfOrange10;
export const rfOrange = colorToken.rfOrange;

