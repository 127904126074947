import { Image, ImageProps } from '$shared/components';
import React from 'react';

export type LoadingIndicatorProps = Omit<ImageProps, 'src'> & {
    width?: number;
    height?: number;
};

export const LoadingIndicator = (props: LoadingIndicatorProps): JSX.Element => {
    const { height = 104, width = 72, ...rest } = props;

    return (
        <Image
            layout={'fixed'}
            objectFit={'contain'}
            width={width}
            height={height}
            // We don't want to optimize an already optimized GIF. One size fits all, literally.
            unoptimized
            {...rest}
            src={'/assets/spinner_250x320.gif'}
        />
    );
};
