import { fonts, japaneseFonts } from '../fonts';
import { fontVariants } from '../design-token-typography';
import { japaneseFontVariants } from '../design-token-typography-jp';

export const isJapaneseFont = (language: string) => {
    return language === 'ja';
};

export const getLanguageFonts = (language: string) =>
    isJapaneseFont(language) ? japaneseFonts : fonts;

export const getLanguageFontVariants = (language: string) =>
    isJapaneseFont(language) ? japaneseFontVariants : fontVariants;
