import { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ModuleSpace } from '~/lib/data-contract';
import { ModuleContainerProps } from './ModuleContainer';
import { ifProp } from 'styled-tools';

type SpaceingValues = keyof Theme['spaces'] | undefined;

type StyledModuleWrapperProps = {
    total: number;
    index: number;
};

const spacings: Record<ModuleSpace, SpaceingValues> = {
    small: '5',
    default: '8',
    none: undefined,
};

const spacingValue = (spacing: ModuleSpace, theme: Theme) => {
    const value = spacings[spacing];
    return value ? theme.spaces[value] : 0;
};

export const StyledModuleWrapper = styled.div<StyledModuleWrapperProps>(({ total, index }) => ({
    position: 'relative',
    zIndex: total - index + 1,
    flex: 1,
}));

export const StyledModule = styled.div<
    ModuleContainerProps & { inView?: boolean; scrollDown?: boolean; disableAnimation?: boolean }
>(
    ({ theme, spacingTop = 'default', spacingBottom = 'default', backgroundColor }) => ({
        paddingTop: spacingValue(spacingTop, theme),
        paddingBottom: spacingValue(spacingBottom, theme),
        margin: '0 auto',
        backgroundColor,
        '.no-js &': {
            opacity: 1,
            transform: 'translateY(0)',
        },
    }),
    ifProp(
        'inView',
        {
            opacity: 1,
            transform: 'translateY(0)',
            transition: 'opacity 1s, transform 0.7s',
        },
        ({ scrollDown }) => ({
            opacity: 0.1,
            transform: scrollDown ? 'translateY(100px)' : 'translateY(-100px)',
        }),
    ),
    ifProp('disableAnimation', {
        transform: 'none',
    }),
);
