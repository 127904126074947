/////
// This file was initially autogenerated from Figma, but has since been modified!
/////
import { CSSObject } from '@emotion/react';
import { fonts } from './fonts';
import { getFluidValue } from './utils/getClamp';

export const body = {
    fontFamily: fonts.body,
    fontSize: 'clamp(1.28rem, 1.1111111111111112vw, 1.6rem)',
    fontWeight: '300',
    letterSpacing: '0.04em',
    lineHeight: '2.1875',
    textTransform: 'none',
} as CSSObject;

export const bodysmall = {
    fontFamily: fonts.body,
    fontSize: 'clamp(0.9600000000000002rem, 0.8333333333333334vw, 1.2rem)',
    fontWeight: '300',
    letterSpacing: '0.04em',
    lineHeight: '1.6',
    textTransform: 'none',
} as CSSObject;

export const display1 = {
    fontFamily: fonts.headline,
    fontSize: 'clamp(4.8rem, 4.166666666666666vw, 6rem)',
    fontWeight: '300',
    letterSpacing: 'normal',
    lineHeight: '1.127',
    textTransform: 'none',
} as CSSObject;

export const display2 = {
    fontFamily: fonts.headline,
    fontSize: 'clamp(4rem, 3.4722222222222223vw, 5rem)',
    fontWeight: '300',
    letterSpacing: 'normal',
    lineHeight: '1.4',
    textTransform: 'none',
} as CSSObject;

export const display3 = {
    fontFamily: fonts.body,
    fontSize: 'clamp(3.2rem, 2.7777777777777777vw, 4rem)',
    fontWeight: '300',
    letterSpacing: 'normal',
    lineHeight: '1.25',
    textTransform: 'none',
} as CSSObject;

export const display4 = {
    fontFamily: fonts.body,
    fontSize: 'clamp(2.4rem, 2.083333333333333vw, 3rem)',
    fontWeight: '300',
    letterSpacing: 'normal',
    lineHeight: '1.4',
    textTransform: 'none',
} as CSSObject;

export const display5 = {
    fontFamily: fonts.headline,
    fontSize: 'clamp(1.6rem, 1.3888888888888888vw, 2rem)',
    fontWeight: '300',
    letterSpacing: 'normal',
    lineHeight: '1.4',
    textTransform: 'none',
} as CSSObject;

export const caption = {
    fontFamily: fonts.body,
    fontSize: 'clamp(1.12rem, 0.9722222222222222vw, 1.4rem)',
    fontWeight: '300',
    letterSpacing: '0.14em',
    lineHeight: '1.4',
    textTransform: 'uppercase',
} as CSSObject;

export const captionsmall = {
    fontFamily: fonts.body,
    fontSize: 'clamp(0.8800000000000001rem, 0.7638888888888888vw, 1.1rem)',
    fontWeight: '300',
    letterSpacing: '0.14em',
    lineHeight: '1.4',
    textTransform: 'uppercase',
} as CSSObject;

export const fontVariants = {
    display1,
    display2,
    display3,
    display4,
    display5,
    display6: display5,
    caption,
    captionSm: captionsmall,
    body,
    bodySm: bodysmall,
    button: {
        fontFamily: fonts.body,
        fontWeight: '300',
        fontSize: getFluidValue(16),
        letterSpacing: '0.04em',
        lineHeight: '1',
        textTransform: 'none',
    } as CSSObject,
};
