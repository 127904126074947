import { theme as defaultTheme, ThemeType } from '$theme';
import type { PotentialThemeColorType, ColorsKeys } from '../model/themeModel';

/**
 * Helper method for looking up color values to the theme colors.
 * Enabling passing theme color tokens as color.
 *
 * @example getPotentialColor('grey20') -> will return the grey20 theme color
 * @example getPotentialColor('#f00') -> not a valid color token and will return #f00
 */
export const getPotentialThemeColor = (
    value: PotentialThemeColorType,
    theme: ThemeType = defaultTheme
) => {
    const key = value.replace(/^\$/, '');
    return theme.colors[key as ColorsKeys] ? theme.colors[key as ColorsKeys] : value;
};
