import { StyledWaveContainer } from './styled';

export const Waves = () => {
    return (
        <StyledWaveContainer>
            <svg
                viewBox="0 0 500 150"
                preserveAspectRatio="none"
                style={{ height: '100%', width: '100%' }}
            >
                <path
                    //d="M0.00,49.98 C198.36,169.25 314.61,63.66 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
                    d="M-33.57,149.52 C0.28,-0.47 498.59,-2.44 539.21,146.55 L525.11,-39.94 L-29.62,-48.83 Z"
                    style={{ stroke: 'none', fill: 'currentColor' }}
                ></path>
            </svg>
        </StyledWaveContainer>
    );
};
