import { useContext } from 'react';
import { PageContext } from '../context/PageProvider';

export const usePage = () => {
    const { page } = useContext(PageContext);
    return page;
};

export const usePageBaseUrl = () => {
    const { pageBaseUrl } = useContext(PageContext);
    return pageBaseUrl;
};
