import React, { FC, ReactNode } from 'react';
import { Text } from '~/shared/components';
import { useError } from '~/shared/utils/errorBoundary/hooks/useError';
import { useTranslation } from '~/shared/utils/translation';
import { ModuleContainer } from '../ModuleContainer';
import { StyledErrorContent } from './styled';
interface ErrorFallbackContentProps {
    children?: ReactNode;
}

export const ErrorFallbackContent: FC<ErrorFallbackContentProps> = ({ children }) => {
    const { translate } = useTranslation();
    const { error } = useError();

    return (
        <ModuleContainer>
            <StyledErrorContent>
                <Text variant="body">
                    {children ? children : translate('Kompan.Message.ContentModuleUnavailable')}
                </Text>
                {error?.message && <div>Error: {error?.message}</div>}
            </StyledErrorContent>
        </ModuleContainer>
    );
};
