import React from 'react';
import { Pages } from '~/lib/data-contract';

type PageProviderProps = {
    children: React.ReactNode;
} & PageProviderContext;

type PageProviderContext = {
    page: Pages;
    pageBaseUrl?: string;
};

export const PageContext = React.createContext<PageProviderContext>({
    page: {} as Pages,
    pageBaseUrl: '',
});

export const PageProvider = ({ children, ...value }: PageProviderProps) => {
    return <PageContext.Provider value={value} children={children} />;
};
