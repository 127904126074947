import { createContext, FC, memo, ReactNode, useCallback, useState } from 'react';

type InViewContextType = {
    inView: boolean;
    setInView: (newState?: boolean) => void;
};

interface InViewContextWrapperProps {
    children: ReactNode;
}

export const InViewContext = createContext<InViewContextType>({
    inView: false,
    setInView: () => undefined,
});

export const InViewContextWrapper: FC<InViewContextWrapperProps> = memo(({ children }) => {
    const [inView, setInView] = useState(false);

    const setInViewCallback = useCallback<InViewContextType['setInView']>((newState) => {
        setInView(typeof newState === 'boolean' ? newState : true);
    }, []);

    return (
        <InViewContext.Provider value={{ inView, setInView: setInViewCallback }}>
            {children}
        </InViewContext.Provider>
    );
});

InViewContextWrapper.displayName = 'InViewContextWrapper';
