import React from 'react';
import { Modules } from '~/lib/data-contract';
import { usePage } from '~/templates/pages';
import { ModuleContext } from '../../context/ModuleContext';
import { DynamicBlock } from '../DynamicBlock';

type Props = {
    elements: Modules[];
    initialIndex?: number;
};

export const DynamicBlockList = ({ elements, initialIndex = 0 }: Props) => {
    const { market = 'int', culture = 'en' } = usePage();
    const localeString = `${culture}-${market}`.toLowerCase();
    if (!elements.length) {
        return null;
    }

    return (
        <>
            {elements.map((element, index) => (
                <ModuleContext.Provider
                    value={{
                        index: index + initialIndex,
                        total: elements.length + initialIndex,
                        element: element,
                    }}
                    key={`${element.id}_${index}_${localeString}`}
                >
                    <DynamicBlock element={element} />
                </ModuleContext.Provider>
            ))}
        </>
    );
};
