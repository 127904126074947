import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { shouldNotForwardProps } from '~/shared/utils/styled';

type StyledImageWrapperProps = {
    loading: boolean;
    disableSkeleton: boolean;
    inView?: boolean;
    disableAnimation?: boolean;
    layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive';
};

const SKELETON_ANIMATION = keyframes({
    from: {
        transform: 'skewX(-10deg) translateX(-200%)',
    },
    to: {
        transform: 'skewX(-10deg) translateX(200%)',
    },
});

export const StyledImageWrapper = styled('span', {
    shouldForwardProp: shouldNotForwardProps(['loading']),
})<StyledImageWrapperProps>(
    ({ theme }) => ({
        '.no-js &': {
            opacity: 1,
            backgroundColor: 'transparent',
            ':before': {
                display: 'none',
            },
            img: {
                opacity: 1,
            },
        },
        img: {
            opacity: 1,
            transitionProperty: 'opacity',
            transitionDuration: '.15s',
            transitionTimingFunction: theme.easings.smoothEase,
        },
    }),
    ifProp({ loading: true, disableSkeleton: false }, ({ theme, layout }) => ({
        position: layout == 'fill' ? 'absolute' : 'relative',
        left: 0,
        top: 0,
        backgroundColor: theme.colors.grey20,
        overflow: 'hidden',
        img: {
            opacity: 0,
        },
        display: 'inline-block',
        width: '100%',
        height: '100%',
        '&::before': {
            content: '""',
            position: 'absolute',
            animation: `${SKELETON_ANIMATION} 2.7s infinite ease-in-out`,
            background: `linear-gradient(
			  90deg,
			  rgba(245, 245, 245, 0),
			  rgba(255, 255, 255, 0.65),
			  rgba(245, 245, 245, 0)
			)`,
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
        },
    })),
    ifProp(
        'inView',
        {
            opacity: 1,
            transition: 'opacity 1.5s',
        },
        {
            opacity: 0,
        }
    ),
    ifProp('disableAnimation', {
        opacity: 1,
        transition: 'opacity 0s',
    })
);
